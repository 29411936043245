import PropTypes from 'prop-types';
import React     from 'react';
import classNames from 'classnames';

import * as S                  from './bm-paid.module.scss';
import { Legal }               from '../../../common/legal';
import { Expiry }              from '../../../common/product/expiry';
import { Price }               from '../../../common/product/price';
import { ProductName }         from '../../../common/product/prod-name';
import { Quota }               from '../../../common/product/quota';
import { TagLine }             from '../../../common/product/tag-line';
import * as GlobalActions      from '../../../../context/ctx-actions';
import { useGlobalCtx }        from '../../../../context/ctx-hook';
import * as ProductSelectors   from '../../../../context/selectors/product-selectors';
import * as BillSelectors      from '../../../../context/selectors/bill-selectors';

import {
  getProductsPaymentMethod,
  getUserAchIsDeclined }       from '../../../../context/selectors/user-selectors';
import { getBMHasEntitlement,
  getBMHasPromoEntitlement 
}                              from '../../../../context/selectors/bill-selectors';
import { BM_ACTIONS }          from '../../../../helpers/constants/constants';
import * as StateConstants     from '../../../../helpers/constants/state-constants';
import { useAutoRenew }        from '../../../../helpers/hooks/use-auto-renew';
import * as NetworkCalls       from '../../../../network/network-calls';
import * as Tealium            from '../../../../helpers/tealium';
import { BmTopToggle }         from '../../../modals/subscription-modal/info-bm/bm-top-up';
import { SubPaymentMethod }    from '../../../modals/subscription-modal/sub-payment-area';

const PRODUCT = 'billmanager';

const getAccountMsg = ({active, autoRenew, tierName}, hasPayment, qknIsActive) => {
  // if (!active) return 'Your Bill Pay subscription is no longer active';
  if (!active) return false;
  else if (!qknIsActive && (!autoRenew || !hasPayment)) return `In order to continue using ${tierName} services, first renew your Quicken Plan.`;
  else if (!autoRenew) return `In order to continue using ${tierName} services, you need to resume your subscription.`;
  else if (!hasPayment) return `In order to continue using ${tierName} services, add a payment method for renewal`;
  else return '';
};

const BmPaid = ({isModal, hideCircle, qknIsActive, listPosition}) => {
  const {globalDispatch, state} = useGlobalCtx();
  // const HAS_ACH      = getUserHasAch(state);
  const PRODUCT_PAYMENT = getProductsPaymentMethod(PRODUCT, state);
  const HAS_ACH         = PRODUCT_PAYMENT ? PRODUCT_PAYMENT?.paymentMethodType === 'ACH' : false;
  const ACH_DECLINED = (HAS_ACH ? getUserAchIsDeclined(PRODUCT, state) : false);
  const SUB          = ProductSelectors.getSubscription(state, PRODUCT);
  const MESSAGE      = getAccountMsg(SUB, HAS_ACH, qknIsActive);

  const BM_MAX_ENT = BillSelectors.getMaxEntitlement(state);
  const BM_ENT_DATA = BillSelectors.getBmTierData(BM_MAX_ENT);

  const {active, autoRenew, tierUriName, sku, tierName, renewalTierName, renewalFrequency, renewalTierUriName} = SUB;

  const SAME_PLAN = (renewalTierName === undefined) || (renewalTierName === tierName);

  const hangLengthClass = ( listPosition > 0 ?  (listPosition > 1 ? S.extraHanging : S.longHanging) : S.shortHanging);

  const handleAROn = useAutoRenew(PRODUCT, true);

  const handleUpdateBilling = React.useCallback(
    () => globalDispatch(GlobalActions.setCtxNestedObj('modal', {
      type:       StateConstants.MODALS.UPDATE_PAYMENT,
      ctx:        {
        product:     PRODUCT,
        plan:        tierUriName ?? null,
        paymentOnly: false
      },
      isLoading:  true,
      hasError:   false,
      isDisabled: false
    })),
    [globalDispatch, tierUriName]
  );

  const handleAchDeclined = React.useCallback(
    () => globalDispatch(GlobalActions.setCtxNestedObj('modal', {
      type: StateConstants.MODALS.UPDATE_PAYMENT,
      isLoading: true,
      hasError: false,
      isDisabled: false,
      ctx: {
        product: PRODUCT,
        paymentOnly: true
      }
    })),
    [globalDispatch, tierUriName]
  );


  const reActivateSubscription = async () => {
    const {data} = await NetworkCalls.callPostChangeTier(tierUriName, sku, BM_ACTIONS.CREATE);
    // Tealium.tealiumLink(Tealium.BMTealiumEvent(state, data));
    Tealium.gtmLink(Tealium.BMTealiumEvent(state, data));

    globalDispatch(GlobalActions.mergeSubscriptionData(PRODUCT, data.postState));
  };


  const manageSubscription = React.useCallback(
    async () => globalDispatch(GlobalActions.setCtxNestedObj('modal', {
      type:       StateConstants.MODALS.SUBSCRIPTION,
      ctx:        {product: PRODUCT},
      isLoading:  true,
      hasError:   false,
      isDisabled: false
    })),
    [globalDispatch]
  );

  const RENEWAL_SKU = ProductSelectors.uriNameToSku(renewalTierUriName);
  const SKU         = ProductSelectors.uriNameToSku(tierUriName);

  const IS_STANDARD   = getBMHasEntitlement(state, ['BILLPAY_STANDARD']);
  const IS_EXTRA      = getBMHasEntitlement(state, ['BILLPAY_EXTRA']);
  const IS_PROMO_ENT  = getBMHasPromoEntitlement(state);
  const BM_ENT = IS_PROMO_ENT ? ProductSelectors.getSubscription(state, 'billmanager-promotion') : false;
  const HAS_BM_FREE   = (IS_STANDARD || IS_EXTRA || IS_PROMO_ENT);
  const qknSub        = ProductSelectors.getSubscription(state, `quicken-${state.country}`);
  const isExpiredView = !active && HAS_BM_FREE;
  const tileTierColor = (isExpiredView ? (IS_STANDARD ? 'standard' : 'extra') : tierUriName);

  return (
    <div className={`${isModal ? S.bmModalTile : S.bmTile} ${tileTierColor}`}>
      {!isModal && !hideCircle && <span className={classNames(`${S.plusSymbol} ${hangLengthClass}`)}>+</span>}

      {/* Show underlying entitlements if they exist and the users BM sub is expired */}
      {!active && HAS_BM_FREE && (
        <div className={S.expiredEntitlements}>
          {IS_PROMO_ENT ?
            <ProductName sub={BM_ENT} product='bill manager' className={S.qknName} /> :
            <ProductName sub={BM_ENT_DATA} product='bill manager' className={S.qknName} />
          }

          <TagLine uriName={IS_STANDARD ? 'standard' : 'extra'} className={S.qknTagLine} />

          <div className={S.payCounts}>
            <p>Check Pay - {BM_ENT_DATA.checkPay} per month</p>
            <p>Quick Pay - {BM_ENT_DATA.quickPay} per month</p>
          </div>

          {IS_PROMO_ENT &&
            <Expiry product={ IS_STANDARD ? PRODUCT : 'billmanager-promotion'} className={S.bmExpiry} />
          }

          {!IS_PROMO_ENT &&
            <p className={S.benefit}>Included with Quicken {qknSub.tierName}</p>
          }

          <span className={S.dash} />

          <div className={`${S.qknName} ${S.prevPlan}`}> Previous Plan </div>
        </div>
      )}
      
      <ProductName sub={SUB} product='bill manager' className={S.qknName} />

      
      <TagLine uriName={SUB.tierUriName} className={S.qknTagLine}/>

      {!isModal && MESSAGE && <div className={S.productAlert}>{MESSAGE}</div>}

      <Quota sku={SKU} className={S.topQuota}/>

      {SAME_PLAN && (
        <div>
          <Price sku={SKU} renewalFrequency={renewalFrequency} className={S.price}/>
          <Expiry product={PRODUCT}/>
        </div>
      )}

      {!SAME_PLAN && (
        <div className={S.renewalArea}>
          <div className={S.renewalLabel}>Renewal Level</div>
          <ProductName customName={`Bill Manager ${renewalTierName}`} product='bill manager' className={S.qknName}/>

          <Quota sku={RENEWAL_SKU}/>

          <Price
            className={S.price}
            renewalFrequency={renewalFrequency}
            sku={RENEWAL_SKU}
          />

          <Expiry product={PRODUCT} className={S.bmExpiry}/>

        </div>
      )}

      {
        (!isModal || 
          (isModal && (!active || !autoRenew || !HAS_ACH))
        ) &&
        !isExpiredView && 
          <span className={S.dash}/>
      }

      { isExpiredView &&
        <span className={S.spaceDash} />
      }

      <div className={S.bmTilePayment}>
        <BmTopToggle
          custClass="tileToggle"
        />

        {HAS_ACH &&
          <SubPaymentMethod 
            product={PRODUCT}
            custClass="tilePay"
          />
        }
      </div>

      {!isModal && active && autoRenew && HAS_ACH && !ACH_DECLINED && (
        <button
          type="button"
          onClick={manageSubscription}
          className={S.bpCTA}
          data-testid="BTN_BM_MANAGE_SUB"
        >Manage Subscription</button>
      )}

      {active && autoRenew && HAS_ACH && ACH_DECLINED && (
        <button
          type="button"
          onClick={handleAchDeclined}
          className={S.bpCTA}
          data-testid="BTN_BM_MANAGE_DECLINED_SUB"
        >Fix Billing Account</button>
      )}

      {active && autoRenew && !HAS_ACH && (
        <button
          type="button"
          onClick={handleUpdateBilling}
          className={S.bpCTA}
          data-testid="BTN_BM_ADD_BILLING"
        >Add Billing</button>
      )}

      {active && !autoRenew && (
        <>
          <button
            type="button"
            onClick={HAS_ACH ? handleAROn : handleUpdateBilling}
            className={S.bpCTA}
            data-testid="BTN_BM_RESTART_SUB"
          >Resume Subscription</button>
          <Legal className={S.legal}/>
        </>
      )}

      {qknIsActive && !active && (
        <button
          type="button"
          onClick={HAS_ACH ? reActivateSubscription : handleUpdateBilling}
          className={S.bpCTA}
          data-testid="BTN_BM_RENEW_NOW"
        >Reactivate</button>
      )}
    </div>
  );
};

BmPaid.displayName = 'BmTile';
BmPaid.propTypes   = {
  isModal:     PropTypes.bool,
  hideCircle:  PropTypes.bool,
  qknIsActive: PropTypes.bool,
  listPosition: PropTypes.number
};

BmPaid.defaultProps = {
  isModal:    false,
  hideCircle: false,
  listPosition: 0
};

export { BmPaid };
